.test {
  color: #2d61a5;
}

.sand {
  width: 100%;
  background: linear-gradient(to top, #fdd9b5 0%, #fff6bd 100%);
  background-repeat: no-repeat;
}
.ocean {
  width: 100%;
  background: linear-gradient(to top, #033270 0%, #2d61a5 95%, #2d61a5 100%);
  background-repeat: no-repeat;
}
.pt-full {
  padding-top: 100%;
}

.my-green {
  background-color: #06b37f;
}

.my-green:hover {
  background-color: #88d9b0;
}

.my-red {
  background-color: #f04660;
}

.my-red:hover {
  background-color: #fd9797;
}

/* TIMEPICKER */
.table-drag-select {
  width: 100%;
  table-layout: fixed;
  user-select: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

.table-drag-select td {
  border: 0.05rem solid transparent;
  background-clip: padding-box;
}

.table-drag-select td.cell-enabled {
  background-color: #88d9b0;
  cursor: cell;
}

.table-drag-select td.cell-selected {
  background-color: #fd9797;
}
/* Cells that are in the process of being selected */
.table-drag-select td.cell-being-selected {
  background-color: #2d61a5;
}
/* Cells in first row */
.table-drag-select tr:first-child td {
  /* Remove empty space at top of table */
  border-top: 0;
  line-height: 6rem;
}

.table-drag-select tr:first-child td.cell-disabled {
  color: #fff;
  line-height: 1rem;
  text-align: left;
  background-clip: padding-box;
  background-color: transparent;
}

.table-drag-select tr:first-child td.cell-blank {
  color: #806e52;
  line-height: 1rem;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  background-clip: padding-box;
  background-color: #2d61a5;

  width: 0rem;
}

.table-drag-select td:hover {
  background-color: #2d61a5;
}

/* WAVES */

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  min-height: 100px;
  max-height: 150px;
  background: linear-gradient(to top, #2d61a5 0%, #2d61a5 5%, #fdd9b5 5%, #fdd9b5 100%);
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  /* animation: bounce 2s linear infinite; */
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translateX(-90px) translateY(1px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateX(85px) translateY(1px);
  }
}
